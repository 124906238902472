export class View {
  addhandlerNavClick(func) {
    this._parentElement.addEventListener("click", function (event) {
      event.preventDefault();
      func();
    });
  }

  scrollBehave(href) {
    const select = document.querySelector(href);
    select.scrollIntoView({
      behavior: "smooth",
    });
  }

  addHandlerLink(func) {
    this._excludeLink.addEventListener("click", function (event) {
      event.preventDefault();
      const getLink = event.target.getAttribute("href");
      if (!getLink) return;
      func(getLink);
    });
  }

  openNewLink(href) {
    window.open(href);
  }
}
