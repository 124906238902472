import { View } from "./view.js";

class LogoLink extends View {
  _parentElement = document.querySelector(".footer_logo");

  windowScroll() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
}

export default new LogoLink();
