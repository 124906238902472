import opanNav from "./veiw/opanNav.js";
import navScroll from "./veiw/navScroll.js";
import aloneLink from "./veiw/aloneLink.js";
import lineLink from "./veiw/lineLink.js";
import footerList from "./veiw/footerList.js";
import scrollToTop from "./veiw/scrollToTop.js";
const openNavbar = function () {
  opanNav.renderOpen();
};
const scrollBehavior = function (href) {
  navScroll.scrollBehave(href);
  opanNav.renderOpen();
};

const aloneLinks = function (href) {
  aloneLink.scrollBehave(href);
};
const excludeLinks = function (href) {
  navScroll.openNewLink(href);
  opanNav.renderOpen();
};
const lineLinks = function (href) {
  lineLink.openNewLink(href);
};
const closeNav = function (element) {
  navScroll.removeNav(element);
};
const goToTop = function () {
  scrollToTop.windowScroll();
};
const openSocail = function (href) {
  footerList.openNewLink(href);
};
const tel = function (href) {
  footerList.openTel(href);
};

const init = function () {
  opanNav.addhandlerNavClick(openNavbar);
  navScroll.addHandlerScroll(scrollBehavior);
  aloneLink.addHandlerAlone(aloneLinks);
  lineLink.addHandlerLink(lineLinks);
  navScroll.addHandlerLink(excludeLinks);
  navScroll.addHandlerBackground(closeNav);
  scrollToTop.addhandlerNavClick(goToTop);
  footerList.addHandlerFooter(openSocail);
  footerList.addHandlerExcludeFooter(tel);
};
init();
