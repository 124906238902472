import { View } from "./view.js";

class OpenNav extends View {
  _parentElement = document.querySelector(".navigation_button");
  _inputElement = document.querySelector(".navigation_checkbox");

  renderOpen() {
    this._inputElement.toggleAttribute("checked");
  }
}

export default new OpenNav();
