import { View } from "./view.js";

class NavScroll extends View {
  _parentElement = document.querySelector(".navigation_list");
  _excludeLink = document.querySelector(".navigation_exclude");
  _background = document.querySelector(".navigation_nav");

  addHandlerScroll(func) {
    this._parentElement.addEventListener("click", function (event) {
      event.preventDefault();
      if (
        !event.target.classList.contains("navigation_link") ||
        event.target.classList.contains("navigation_exclude")
      )
        return;
      const getHref = event.target.getAttribute("href");
      func(getHref);
    });
  }

  addHandlerBackground(func) {
    this._background.addEventListener("click", function (event) {
      event.preventDefault();
      const getInput = event.target
        .closest(".navigation")
        .querySelector(".navigation_checkbox");
      func(getInput);
    });
  }

  removeNav(element) {
    element.removeAttribute("checked");
  }
}

export default new NavScroll();
