import { View } from "./view.js";

class Links extends View {
  _parentElement = document.querySelector(".btn");

  addHandlerAlone(func) {
    this._parentElement.addEventListener("click", function (event) {
      event.preventDefault();
      const target = event.target.closest(".btn");
      if (!target) return;
      const href = target.getAttribute("href");
      func(href);
    });
  }
}

export default new Links();
