import { View } from "./view.js";

class FooterLink extends View {
  _parentElement = document.querySelector(".footer_list");

  addHandlerFooter(func) {
    this._parentElement.addEventListener("click", function (event) {
      event.preventDefault();
      const target = event.target.closest(".footer_link");
      const except = event.target.closest(".footer_exclude");
      if (!target || except) return;
      const href = target.getAttribute("href");
      func(href);
    });
  }

  addHandlerExcludeFooter(func) {
    this._parentElement.addEventListener("click", function (event) {
      event.preventDefault();
      const except = event.target.closest(".footer_exclude");
      if (!except) return;
      const href = except.getAttribute("href");
      func(href);
    });
  }

  openTel(tel) {
    window.open(tel, "_self");
  }
}

export default new FooterLink();
